<template>
	<ConfirmDialog></ConfirmDialog>
	<ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
		<li>
			<a class="text-500 no-underline line-height-3 cursor-pointer">Configuraciones</a>
		</li>
		<li class="px-2">
			<i class="pi pi-angle-right text-500 line-height-3"></i>
		</li>
		<li>
			<span class="text-900 line-height-3">Dosificaci&oacute;n</span>
		</li>
	</ul>
	<div class="surface-section px-4 py-5 md:px-12 lg:px-12">
		<Toast />
		<div
			class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
		>
			<div>
				<div class="font-medium text-3xl text-900">Administrar Dosificaciones <Badge size="xlarge" severity="success"> {{ totalRegistros }}</Badge></div>
			</div>
			<div class="flex mt-3 lg:mt-0">
        <!-- v-if="'Medico Crear' in auth.user.permissions" -->
				<Button
					label="Nuevo"
					v-tooltip.top="'Nuevo Dosificación'"
					icon="pi pi-plus"
					class="p-button-outlined mr-2 p-button-lg"
					@click="openNuevo"
				/>
			</div>
		</div>
		<div class="mt-4">
			<DataTable
				ref="dtdosificaciones"
				:value="dosificaciones"
				:loading="cargando"
				dataKey="id"
				:paginator="true"
				:rows="10"
				:rowHover="true"
				:filters="buscar"
				class="p-datatable-sm"
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				:rowsPerPageOptions="[10, 20, 25]"
				currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Dosificaciones"
				responsiveLayout="scroll"
				showGridlines
			>
				<template #header>
					<div
						class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
					>
						<span class="block mt-2 md:mt-0 p-input-icon-left">
							<i class="pi pi-search" />
							<InputText
								v-model="buscar['global'].value"
								placeholder="Buscar Dosificaciones..."
							/>
						</span>
					</div>
				</template>

				<template #loading>
					<div class="flex align-items-center justify-content-center">
						<ProgressSpinner />
					</div>
				</template>
				<template #empty>
					<span class="flex align-items-center justify-content-center p-invalid"
						>No existen Registros!</span
					></template
				>
				<Column header="#">
					<template #body="slotProps">
						{{ 1 + slotProps.index }}
					</template>
				</Column>
        <Column field="llave" header="LLAVE"></Column>
        <Column field="autorizacion" header="AUTORIZACIÓN"></Column>
        <Column field="nrofactura" header="NRO. FACTURA"></Column>
				<Column field="fechareg" header="FECHA CREACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.fechareg) }}
          </template>
        </Column>
				<Column field="fechalimite" header="FECHA LÍMITE">
          <template #body="{ data }">
            {{ formatDate(data.fechalimite) }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
				<Column header="ACCIONES">
					<template #body="slotProps">
						<Button
							class="p-button-icon-only p-button-raised"
							v-tooltip.top="'Ver Acciones'"
							icon="pi pi-ellipsis-v"
							@click="$refs['menu' + slotProps.data.id].toggle($event)"
						/>
						<Menu
							:ref="'menu' + slotProps.data.id"
							:popup="true"
							:model="acciones(slotProps.data)"
						>
						</Menu>
					</template>
				</Column>
			</DataTable>
		</div>
		<DosificacionCreate
			:show="dosificacionDialog"
			:dosificacion="dosificacion"
			@closeModal="ocultarDialog"
			@actualizarListado="cargarDosificacion"
		>
		</DosificacionCreate>
	</div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import DosificacionService from "@/service/DosificacionService";
import { useAuth } from "@/stores";
import DosificacionCreate from "@/module/dosificaciones/DosificacionCreate.vue";

export default {
	components: {
		DosificacionCreate,
	},
	data() {
		return {
			dosificaciones: [],
			cargando: true,
			dosificacionDialog: false,
			deleteMedicoDialog: false,
			dosificacion: {},
			buscar: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
			enviado: false,
			estados: [
				{ label: "INACTIVO", value: 0 },
				{ label: "ACTIVO", value: 1 },
			],
			errors: {},
			totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
		};
	},
	dosificacionService: null,
	auth: null,
	created() {
		this.auth = useAuth();
		this.dosificacionService = new DosificacionService();
	},
	mounted() {
		this.cargarDosificacion();
	},
	methods: {
		acciones(datos) {
			/* let permisosUsuarioAuth = this.auth.user.permissions; */
			return [
				{
					label: "Editar",
					/* disabled: "Medico Editar" in permisosUsuarioAuth ? false : true, */
					icon: "pi pi-pencil",
					command: () => {
						this.editDosificacion(datos);
					},
				},
				{
					label: "Eliminar",
					/* disabled: "Medico Eliminar" in permisosUsuarioAuth ? false : true, */
					icon: "pi pi-trash",
					command: () => {
						this.deletDosificacion(datos.id);
					},
				},
			];
		},
    deletDosificacion(id) {
			this.$confirm.require({
				message: "¿Está seguro que desea eliminar Dosificación?",
				header: "Confirmación",
				icon: "pi pi-exclamation-triangle",
				acceptLabel: "Si, deseo eliminar",
				rejectLabel: "No, cancelar",
				accept: () => {
					this.dosificacionService.deleteDosificacion(id)
						.then((response) => {
							if(response.status == 200){
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: response.mensaje,
                  life: 3000,
                });
                this.cargarDosificacion();
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.mensaje,
                  life: 3000,
                });
              }
            })
				},
			});
		},
		cargarDosificacion() {
			this.dosificacionService.getDosificacionAll().then((data) => {
				this.dosificaciones = data.dosificaciones;
				this.cargando = false;
			});
		},
		openNuevo() {
			this.dosificacion = {
				estado: { label: "Activo", value: 1 },
			};
			this.enviado = false;
			this.dosificacionDialog = true;
		},
		ocultarDialog() {
			this.dosificacionDialog = false;
			this.enviado = false;
		},
    editDosificacion(datos) {
      this.dosificacion = {
        ...datos,
        estado: {
          label: datos.estado_texto,
          value: datos.estado,
        },
      };
      this.dosificacionDialog = true;

      this.enviado = false;
    },
		formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return "";
    },
	},
	watch: {
		dosificaciones() {
			this.totalRegistros = this.dosificaciones.length;
		},
	},
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
	color: red;
}
</style>